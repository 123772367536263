export default class PromoBlock {
  constructor(container, options) {
    const defaultOptions = {
    }

    this.options = Object.assign({}, defaultOptions, options)
    this.container = document.querySelector(container)
    this.blocks = this.container.querySelectorAll('.promo-tiles-tile')

    this.setEvents()
  }

  setEvents() {
    this.animateBlock()
  }

  animateBlock() {
    const offset = window.innerWidth >= 768 ? '-200px' : '0px'
  
    this.blocks.forEach(block => {
      block.classList.remove('tile-transition')
      
      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            block.classList.add('tile-transition')
            observer.unobserve(block)
          } else {
            block.classList.remove('tile-transition')
          }
        })
      }, {
        root: null,
        threshold: 0.00000000001,
        rootMargin: offset
      });
      
      observer.observe(block)
    })
  }
}